
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import ImageUtils from "@evercam/shared/mixins/imageUtils"

export default {
  name: "CopyImageToClipboard",
  components: { EvercamLoadingAnimation },
  mixins: [ImageUtils],
  props: {
    imageSrc: {
      type: String,
      default: null,
    },
    watermarkLogoUrl: {
      type: String,
      default: "",
    },
    watermarkText: {
      type: String,
      default: "",
    },
    showWatermark: {
      type: Boolean,
      default: true,
    },
    getImageSrc: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      isCopying: false,
    }
  },
  methods: {
    async copyImageToClipboard() {
      this.$emit("copy-image-to-clipboard")

      if (this.isCopying) {
        return
      }

      try {
        this.isCopying = true
        const imageSrc = this.imageSrc || (await this.getImageSrc())

        let watermarkParams = {}
        if (this.showWatermark) {
          watermarkParams = {
            watermarkText: this.watermarkText,
            watermarkLogoUrl: this.watermarkLogoUrl,
          }
        }

        await this.copyToClipboard({
          imageSrc,
          ...watermarkParams,
        })

        this.$notifications.success(
          this.$t("content.copy_image_success").toString()
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.copy_image_failed").toString(),
          error,
        })
      } finally {
        this.isCopying = false
      }
    },
  },
}
