
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "DropDownButton",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [
        {
          name: "",
          value: "",
          logo: "",
          icon: "",
        },
      ],
    },
    buttonText: {
      type: String,
      default: "Send to",
    },
    icon: {
      type: String,
      default: "",
    },
    right: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: "",
    },
  },
}
