
import AutodeskSelection from "@/components/connectors/AutodeskSelection"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { Connector } from "@evercam/shared/types/connector"
import Vue from "vue"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useConnectorStore } from "@/stores/connector"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import DropDownButton from "@evercam/shared/components/DropDownButton"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import AconexForm from "@/components/connectors/AconexForm"
import { AconexDocumentSchemaField } from "@evercam/shared/types"

export default Vue.extend({
  name: "SelectedImage",
  components: {
    AutodeskSelection,
    DropDownButton,
    EvercamLoadingAnimation,
    AconexForm,
  },
  props: {
    imageTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      connectors: [
        {
          name: "Procore",
          value: Connector.Procore,
          logo: require("~/assets/img/procore_logo.png"),
        },
        {
          name: "Aconex",
          value: Connector.Aconex,
          logo: require("~/assets/img/aconex_logo.png"),
        },
        {
          name: "Autodesk",
          value: Connector.Autodesk,
          logo: require("~/assets/img/acc_logo.png"),
        },
      ],
      sendToFormDialog: false,
      isLoading: false,
      selectedProviderItem: "",
      selectedProviderItemName: "",
      selectedAconexProject: null,
      selectedProcoreProject: null,
      selectedProcoreProjectAlbum: null,
      selectedAutodeskHub: null,
      selectedAutodeskProject: null,
      selectedAutodeskFolder: null,
      uploadImageData: "",
      aconexDocumentSchema: null,
      aconexForm: {},
      stepper: 1,
    }
  },
  computed: {
    ...mapStores(
      useLayoutStore,
      useConnectorStore,
      useProjectStore,
      useCameraStore
    ),
    autodeskFolderName() {
      const selectedFolder = this.selectedAutodeskFolder?.[0]

      return selectedFolder
        ? (selectedFolder.parentName ?? "") + selectedFolder.text
        : null
    },
    canSendImage() {
      return (
        ((this.selectedAconexProject && this.isAconexActive) ||
          (this.selectedProcoreProject && this.isProcoreActive) ||
          (this.selectedAutodeskFolder && this.isAutodeskActive)) &&
        !this.isLoading &&
        !this.connectorStore.isProcoreAlbumFetchFailed
      )
    },
    isProcoreActive() {
      return (
        this.selectedProviderItem === Connector.Procore &&
        this.connectorStore.isProcoreConnected
      )
    },
    isAconexActive() {
      return (
        this.selectedProviderItem === Connector.Aconex &&
        this.connectorStore.isAconexConnected
      )
    },
    isAutodeskActive() {
      return (
        this.selectedProviderItem === Connector.Autodesk &&
        this.connectorStore.isAutodeskConnected
      )
    },
  },
  watch: {
    "connectorStore.uploadImageData": {
      handler(value) {
        this.uploadImageData = value
      },
      immediate: true,
    },
    selectedAconexProject(project) {
      this.getAconexDocumentSchema(project.id)
    },
    sendToFormDialog(value) {
      if (!value) {
        this.clearForm()
      }
    },
  },
  methods: {
    clearForm() {
      this.selectedProviderItem = null
      this.selectedProviderItemName = ""
      this.selectedAconexProject = null
      this.selectedProcoreProject = null
      this.selectedProcoreProjectAlbum = null
      this.selectedAutodeskHub = null
      this.selectedAutodeskProject = null
      this.selectedAutodeskFolder = null
      this.aconexDocumentSchema = null
      this.aconexForm = {}
      this.stepper = 1
      this.sendToFormDialog = false
    },
    async validateAconexForm(): Promise<boolean> {
      return await this.$refs.aconexForm.$refs.observer.validate()
    },
    initDialog(provider) {
      this.$emit("update-connector-image")
      this.selectedProviderItemName = provider.name
      this.selectedProviderItem = provider.value
      this.getProviderItem(provider)
      if (!this.connectorStore.connectorPromptDialog) {
        this.sendToFormDialog = true
      }
    },
    async uploadImageToConnector() {
      if (this.selectedProviderItem === Connector.Procore) {
        this.uploadImageToProcore()
      } else if (this.selectedProviderItem === Connector.Aconex) {
        if (
          this.selectedAconexProject &&
          this.aconexDocumentSchema &&
          (await this.validateAconexForm())
        ) {
          this.uploadImageToAconex()
        }
      } else if (this.selectedProviderItem === Connector.Autodesk) {
        this.uploadImageToAutodesk()
      }
    },
    async uploadImageToAutodesk() {
      try {
        this.isLoading = true
        await EvercamApi.autodesk.uploadEditedSnapshot({
          projectExid: this.projectStore.selectedProject?.exid,
          snapshot: this.uploadImageData,
          folderId: this.selectedAutodeskFolder?.value,
          projectId: this.selectedAutodeskProject?.value,
          accFileType: this.selectedAutodeskFolder?.allowedFileType,
          accFolderType: this.selectedAutodeskFolder?.allowedFolderType,
        })
        this.$notifications.success(
          this.$t("content.connectors.upload_success")
        )
        this.selectedAutodeskHub = null
        this.selectedAutodeskProject = null
        this.selectedAutodeskFolder = null
        this.selectedProviderItem = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_autodesk_failed"),
          error,
        })
      } finally {
        this.$emit("uploaded", { connector: Connector.Autodesk })
        this.sendToFormDialog = false
        this.isLoading = false
      }
    },
    async uploadImageToAconex() {
      try {
        this.isLoading = true
        await EvercamApi.aconex.uploadEditedSnapshot(
          this.selectedAconexProject.id,
          {
            ...this.aconexForm,
            projectExid: this.projectStore.selectedProject?.exid,
            snapshot: this.uploadImageData,
          }
        )
        this.$notifications.success(
          this.$t("content.connectors.upload_success")
        )
        this.selectedAconexProject = null
        this.selectedProviderItem = null
        this.$emit("uploaded", { connector: Connector.Aconex })
        this.sendToFormDialog = false
      } catch (error) {
        this.$notifications.error({
          text:
            error.response.data.message ||
            this.$t("content.connectors.upload_to_aconex_failed"),
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    async uploadImageToProcore() {
      try {
        this.isLoading = true
        await EvercamApi.procore.uploadEditedSnapshot({
          projectExid: this.projectStore.selectedProject?.exid,
          projectId: this.selectedProcoreProject?.id,
          companyId: this.selectedProcoreProject?.company?.id,
          snapshot: this.uploadImageData,
          categoryId: this.selectedProcoreProjectAlbum?.id,
        })
        this.$notifications.success(
          this.$t("content.connectors.upload_success")
        )
        this.selectedProcoreProject = null
        this.selectedProviderItem = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_procore_failed"),
          error,
        })
      } finally {
        this.$emit("uploaded", { connector: Connector.Procore })
        this.sendToFormDialog = false
        this.isLoading = false
      }
    },
    async getAconexDocumentSchema(projectId) {
      try {
        this.isLoading = true
        this.aconexDocumentSchema = await EvercamApi.aconex.getDocumentSchema(
          projectId
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_procore_failed"),
          error,
        })
      } finally {
        this.initAconexForm()
        this.isLoading = false
        this.stepper = 2
      }
    },
    initAconexForm() {
      this.aconexForm = this.aconexDocumentSchema.reduce((acc, item) => {
        return Object.assign(acc, {
          [item.identifier]: this.getDefaultValue(item),
        })
      }, {})
    },
    getDefaultValue(field: AconexDocumentSchemaField): string | boolean {
      if (field.identifier === "HasFile") {
        return true
      }
      if (field.identifier === "Title") {
        return this.imageTitle
      }
      if (field.identifier === "Author") {
        return "Evercam Ltd"
      }
      if (field.identifier === "DocumentTypeId") {
        const typeValues = this.aconexDocumentSchema.find(
          (item) => item.identifier === "DocumentTypeId"
        )?.schemaValues

        return typeValues.find((item) => item.value === "Image")?.id
      }
      if (field.identifier === "DocumentNumber") {
        return `${
          this.cameraStore.selectedCamera?.exid
        }_${this.$moment().unix()}`
      }
      if (field.dataType === "BOOLEAN") {
        return false
      }
      if (field.dataType === "DATE") {
        return this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      }

      return ""
    },
    getProviderItem(item) {
      this.selectedProviderItem = item.value
      this.openConnectorPromptDialog()
    },
    openConnectorPromptDialog() {
      const shouldOpenConnectorPromptDialog =
        (this.selectedProviderItem === Connector.Procore &&
          !this.connectorStore.isProcoreConnected) ||
        (this.selectedProviderItem === Connector.Aconex &&
          !this.connectorStore.isAconexConnected) ||
        (this.selectedProviderItem === Connector.Autodesk &&
          !this.connectorStore.isAutodeskConnected)
      if (shouldOpenConnectorPromptDialog) {
        this.connectorStore.openConnectorPromptDialog(this.selectedProviderItem)
      }
    },
    async fetchProcoreProjectAlbums() {
      this.isLoading = true
      await this.connectorStore.getProcoreProjectAlbums({
        companyId: this.selectedProcoreProject?.company?.id,
        projectId: this.selectedProcoreProject?.id,
      })
      this.isLoading = false
    },
  },
})
