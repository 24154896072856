
import { mapStores } from "pinia"

import MESSAGES_TYPES from "@evercam/shared/constants/iframeMessagesTypes"
import {
  AnalyticsEvent,
  WatermarkedImageDownloadOrigin,
} from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { useConnectorStore } from "@/stores/connector"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"

import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import SnapshotEditor from "@/components/SnapshotEditor"

export default {
  name: "SitePlannerViewer",
  components: { EvercamLoadingAnimation, FrameViewer, SnapshotEditor },
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
    sitePlanId: {
      type: [String, null],
      default: null,
    },
    viewType: {
      type: String,
      default: "list",
    },
  },
  data: () => ({
    isEditingImage: false,
    isLoadingIFrame: true,
    isSitePlannerViewerMounted: false,
    currentSnapshot: null,
    currentQueryParams: {},
    sitePlanData: {},
    WatermarkedImageDownloadOrigin,
  }),
  computed: {
    ...mapStores(
      useProjectStore,
      useAccountStore,
      useConnectorStore,
      useLayoutStore
    ),
    firstCamera() {
      // Return a dummy camera when the SitePlannerViewer is outside of a project scope
      // Since the evercam projects are currently based on cameras, not having one can cause crash for some features used outside of the project scope (like the mark-up tool)
      if (!this.projectStore.selectedProject) {
        return {
          exid: "aites-qtryp",
          id: "aites-qtryp",
          name: "Ai test caemera",
        }
      }

      return this.projectStore.selectedProjectCameras[0] || {}
    },
    origin() {
      return `${this.$config.public.sitePlannerIframeUrl}`
    },
    params() {
      const iFrameQuery = new URLSearchParams({
        ...this.currentQueryParams,
        isNewSitePlan: this.isNew,
        page: this.viewType,
        sitePlanId: this.sitePlanId,
        theme: this.$vuetify.theme.dark ? "dark" : "light",
        unit: this.accountStore.unitSystem,
        user: this.$route.query.user ?? "",
      }).toString()

      return `?${iFrameQuery}`
    },
    selectedProject() {
      if (!this.projectStore.selectedProject) {
        // Returns default project info for the snapshot editor used outside of a selected project scope.
        return {
          exid: "evercam",
          name: "Evercam",
          timezone: this.$moment.tz?.guess(),
        }
      }

      return this.projectStore.selectedProject
    },
  },
  async mounted() {
    this.isSitePlannerViewerMounted = true
  },
  methods: {
    async getFrameDimensions() {
      const viewer = await document.querySelector(".site-planner-viewer")
      if (!viewer) {
        return null
      }

      return {
        width: viewer?.offsetWidth,
        height: viewer?.offsetHeight,
      }
    },
    onDismissSnapshotEditor() {
      this.isEditingImage = false
    },
    async onMessageReceived(data) {
      switch (data?.type) {
        case MESSAGES_TYPES.analyticsEvent:
          const extraParams = { ...data?.params, sitePlanId: this.sitePlanId }
          this.$analytics.saveEvent(data?.action, extraParams)
          break
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url, data?.sitePlanData)
          }
          break
        case MESSAGES_TYPES.loadSitePlannerList:
          this.$emit("click-back-button")
          break
        case MESSAGES_TYPES.ready:
          this.isLoadingIFrame = false
          this.$analytics.saveEvent(AnalyticsEvent.SitePlannerOpen)
          this.sendProjectInfo(data?.origin)
          break
        default:
          break
      }
    },
    onUpdateConnectorImage(value) {
      this.connectorStore.uploadImageData = value
    },
    openImageEditor(urlData, sitePlanData) {
      this.$analytics.saveEvent(AnalyticsEvent.MarkUpToolOpen)
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
        camera: this.firstCamera,
      }
      this.isEditingImage = true

      this.sitePlanData = sitePlanData
    },
    pushQueryParams(parameters) {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        ...parameters,
      }
      this.$router.push({
        path: this.$route.path,
        query: this.currentQueryParams,
      })
    },
    async sendProjectInfo(origin) {
      const frameViewerRef = await this.$refs.frameViewer // This can only be a SitePlanner-iFrame
      const frameViewerContainer = frameViewerRef.$refs.container

      frameViewerContainer?.contentWindow?.postMessage(
        {
          type: MESSAGES_TYPES.info,
          user: {
            email: this.accountStore.email,
            name: this.accountStore.fullname,
          },
        },
        origin
      )
    },
  },
}
